@media only screen and (min-width: 768px) {
  .div-1 {
    margin: auto;
    width: 60%;
  }
}

[class*="div-1"] {
  margin: auto;
}
